var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "color-picker" }, [
    _c(
      "ul",
      { staticClass: "type-list js-typeList-control" },
      _vm._l(_vm.items, function(item, key) {
        var _obj
        return _c(
          "li",
          {
            key: item.id,
            class:
              ((_obj = {}),
              (_obj[item.id] = true),
              (_obj["active"] = key === _vm.selectedItem),
              _obj),
            on: {
              click: function($event) {
                return _vm.selectItem(key, item.id)
              }
            }
          },
          [
            _c("div", { staticClass: "thumbnail" }, [
              _c("img", {
                attrs: {
                  src: "images/item-thumbnail_" + item.id + ".png",
                  alt: item.name.ja
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [
              _c("div", { domProps: { innerHTML: _vm._s(item.name.en) } }),
              _vm._v(" "),
              _c("div", { domProps: { innerHTML: _vm._s(item.name.ja) } })
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "color-image" }, [
      _vm.colors[_vm.selectedColor.front]
        ? _c(
            "div",
            {
              staticClass: "color-image__label",
              class: _vm._f("colorID")(_vm.selectedColor.front)
            },
            [
              _c("div", { staticClass: "color" }, [
                _vm._v(_vm._s(_vm.colors[_vm.selectedColor.front].name.en))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "type" }, [
                _vm._v(_vm._s(_vm.items[_vm.selectedItem].name.en))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "price" }, [_vm._v(_vm._s(_vm.price))])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "color-image__image", class: _vm.selectedColor.front },
        [
          _vm._l(_vm.items, function(item, itemKey) {
            return _vm._l(
              _vm.colors.filter(function(color) {
                return color.front !== undefined
              }),
              function(color, index) {
                return _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        itemKey === _vm.selectedItem &&
                        index === _vm.selectedColor.front,
                      expression:
                        "itemKey === selectedItem && index === selectedColor.front"
                    }
                  ],
                  key: item.id + color.id + index,
                  attrs: {
                    src:
                      "images/item/" +
                      _vm.size +
                      "/" +
                      item.prod +
                      "_" +
                      color.id +
                      color.id +
                      "_01_10.jpg",
                    alt: item.name.ja + color.name.ja
                  }
                })
              }
            )
          }),
          _vm._v(" "),
          _vm.selectedColor.front != 0
            ? _c("div", {
                staticClass: "color-image__arrow color-image__arrow--prev",
                on: {
                  click: function($event) {
                    return _vm.prev("front")
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedColor.front !=
          _vm.colors.filter(function(c) {
            return c.rgb
          }).length -
            1
            ? _c("div", {
                staticClass: "color-image__arrow color-image__arrow--next",
                on: {
                  click: function($event) {
                    return _vm.next("front")
                  }
                }
              })
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "color-list js-colorList-control" },
      _vm._l(
        _vm.colors.filter(function(color) {
          return color.front !== undefined
        }),
        function(color, index) {
          var _obj
          return _c(
            "li",
            {
              key: color.id,
              class:
                ((_obj = {}),
                (_obj[color.id] = true),
                (_obj["active"] = index === _vm.selectedColor.front),
                _obj),
              on: {
                click: function($event) {
                  return _vm.select("front", index)
                }
              }
            },
            [
              _c("div", { staticClass: "panel" }),
              _vm._v(" "),
              _c("div", { staticClass: "ja" }, [_vm._v(_vm._s(color.name.ja))])
            ]
          )
        }
      ),
      0
    ),
    _vm._v(" "),
    _vm.items[_vm.selectedItem]
      ? _c("h2", { staticClass: "liner-title" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.items[_vm.selectedItem].name.en) +
              "：裏地\n  "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "color-image color-image--liner" }, [
      _vm.colors[_vm.selectedColor.front]
        ? _c(
            "div",
            {
              staticClass: "color-image__label",
              class: _vm._f("colorID")(_vm.selectedColor.front)
            },
            [
              _c("div", { staticClass: "color" }, [
                _vm._v(_vm._s(_vm.colors[_vm.selectedColor.liner].name.en))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "color-image__image",
          class: _vm._f("colorID")(_vm.selectedColor.liner)
        },
        [
          _vm._l(_vm.items, function(item, itemKey) {
            return _vm._l(_vm.colors, function(color, index) {
              return itemKey === _vm.selectedItem
                ? _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: index === _vm.selectedColor.liner,
                        expression: "index === selectedColor.liner"
                      }
                    ],
                    key: item.id + color.id + index,
                    attrs: {
                      src: _vm.linearImage(item, color),
                      alt: item.name.ja + color.name.ja
                    }
                  })
                : _vm._e()
            })
          }),
          _vm._v(" "),
          _vm.selectedColor.liner != 0
            ? _c("div", {
                staticClass: "color-image__arrow color-image__arrow--prev",
                on: {
                  click: function($event) {
                    return _vm.prev("liner")
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedColor.liner != _vm.colors.length - 1
            ? _c("div", {
                staticClass: "color-image__arrow color-image__arrow--next",
                on: {
                  click: function($event) {
                    return _vm.next("liner")
                  }
                }
              })
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "color-list js-colorList-control" },
      _vm._l(_vm.colors, function(color, index) {
        var _obj
        return _c(
          "li",
          {
            key: color.id,
            class:
              ((_obj = {}),
              (_obj[color.id] = true),
              (_obj["active"] = index === _vm.selectedColor.liner),
              _obj),
            on: {
              click: function($event) {
                return _vm.select("liner", index)
              }
            }
          },
          [
            _c("div", { staticClass: "panel" }),
            _vm._v(" "),
            _c("div", { staticClass: "ja" }, [_vm._v(_vm._s(color.name.ja))])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }