<template>
  <div class="color-picker">

    <ul class="type-list js-typeList-control">
      <li
        v-for="(item, key) in items"
        :key="item.id"
        :class="{[item.id]: true, 'active': key === selectedItem}"
        @click="selectItem(key, item.id)"
      >
        <div class="thumbnail">
          <img :src='`images/item-thumbnail_${item.id}.png`' :alt="item.name.ja">
        </div>
        <div class="text">
          <div v-html="item.name.en"></div>
          <div v-html="item.name.ja"></div>
        </div>
      </li>
    </ul>


    <!--  front -->
    <div class="color-image">
      <div class="color-image__label" v-if="colors[selectedColor.front]" :class="selectedColor.front | colorID">
        <div class="color">{{ colors[selectedColor.front].name.en }}</div>
        <div class="type">{{ items[selectedItem].name.en }}</div>
        <div class="price">{{ price }}</div>
      </div>
      
      <div class="color-image__image" :class="selectedColor.front">
        <template v-for="item, itemKey in items">
          <img
            v-for="(color, index) in colors.filter(color => color.front !== undefined )"
            v-show="itemKey === selectedItem && index === selectedColor.front"
            :key="item.id + color.id + index"
            :src="`images/item/${size}/${item.prod}_${color.id}${color.id}_01_10.jpg`"
            :alt="item.name.ja + color.name.ja"
          />
        </template>
        <div v-if="selectedColor.front != 0" class="color-image__arrow color-image__arrow--prev" @click="prev('front')"></div>
        <div v-if="selectedColor.front != colors.filter(c => c.rgb).length - 1" class="color-image__arrow color-image__arrow--next" @click="next('front')"></div>
      </div>
    </div>
    <ul class="color-list js-colorList-control">
      <li
        v-for="(color, index) in colors.filter(color => color.front !== undefined )"
        :key="color.id" 
        :class="{[color.id]: true, 'active': index === selectedColor.front}"
        @click="select('front', index)"
      >
        <div class="panel"></div>
        <div class="ja">{{ color.name.ja }}</div>
      </li>
    </ul>


    <!--  back -->
    <h2 class="liner-title" v-if="items[selectedItem]">
      {{ items[selectedItem].name.en }}：裏地
    </h2>
    <div class="color-image color-image--liner">
      <div class="color-image__label" v-if="colors[selectedColor.front]" :class="selectedColor.front | colorID">
        <div class="color">{{ colors[selectedColor.liner].name.en }}</div>
      </div>

      <div class="color-image__image" :class="selectedColor.liner | colorID">
        <template v-for="item, itemKey in items">
          <img
            v-for="(color, index) in colors"
            v-if="itemKey === selectedItem"
            v-show="index === selectedColor.liner"
            :key="item.id + color.id + index"
            :src="linearImage(item, color)"
            :alt="item.name.ja + color.name.ja"
          />
        </template>


        <div v-if="selectedColor.liner != 0" class="color-image__arrow color-image__arrow--prev" @click="prev('liner')"></div>
        <div v-if="selectedColor.liner != colors.length - 1" class="color-image__arrow color-image__arrow--next" @click="next('liner')"></div>
      </div>
    </div>
    <ul class="color-list js-colorList-control">
      <li
        v-for="(color, index) in colors"
        :key="color.id" 
        :class="{[color.id]: true, 'active': index === selectedColor.liner}"
        @click="select('liner', index)"
      >
        <div class="panel"></div>
        <div class="ja">{{ color.name.ja }}</div>
      </li>
    </ul>
  </div>
  
</template>

<script type="text/javascript">
// import Swiper from 'swiper/bundle'
// import 'swiper/swiper-bundle.css'
import json from '../color.json'


const LIMITED_INDEX = 6


export default {
  data() {
    return {
      device: '',
      items: [],
      colors: [],
      selectedColor: {
        front: 0,
        liner: 0
      },
      selectedItem: '01',
    }
  },
  props: ['itemType'],
  computed: {
    size: function() {
      switch (this.device) {
        case 'sp':
          return 650
        // case 'retina':
        //   return 1600
        default:
          return 800
      }
    },
    price: function () {
      const limited = this.selectedColor.liner >= LIMITED_INDEX
      let value = this.items[this.selectedItem].price
      if(limited ) {
        value += 11000
      }
      // console.log(this.selectedColor.liner)
      return `¥${value.toLocaleString()}`
    }
  },
  methods: {
    linearImage: function(item, color) {
      // console.log(this.selectedColor.front)

      return `images/item/${this.size}/${item.prod}_${this.$options.filters.colorID(this.selectedColor.front)}${color.id}_02_10.jpg`
      
      if ( color.thumb ) {
      const limited = ['BR', 'BK', 'SG', 'OS', 'NV', 'GB']
      const limitedIndex = [1, 0, 3, 4, 5, 2]
        return `images/item/${this.size}/${item.prod}_${color.id}_re${limitedIndex[this.selectedColor.front]}.jpg`
      } else {
        return `images/item/${this.size}/${item.prod}_${this.$options.filters.colorID(this.selectedColor.front)}${color.id}_02_10.jpg`
      }
    },
    prev(side) {
      this.selectedColor[side] = this.selectedColor[side] - 1
    },
    next(side) {
      this.selectedColor[side] = this.selectedColor[side] + 1
    },
    selectItem(key, id) {
      this.selectedItem = key
    },
    select(side, index){
      this.selectedColor[side] = index
    },
    calcMediaQuery() {
      let matched
      if(window.matchMedia('screen and (max-width: 768px)').matches) {
        matched = 'sp'
      } else if (window.devicePixelRatio >= 2 && window.matchMedia('screen and (min-width: 769px)').matches) {
        matched = 'retina'
      } else {
        matched = 'pc'
      }
      this.device = matched
    }
  },
  mounted() {
    window.addEventListener('resize', this.calcMediaQuery)
    this.calcMediaQuery()
    this.colors = json.colors
    this.items = json.items
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcMediaQuery);
  },
  components: {},
}
</script>
