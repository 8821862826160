'use strict'
require('babel-polyfill')
import Vue from 'vue'
import Color from './pages/color.vue'

import colorJson from './color.json'

// import 'ress'
import Swiper from 'swiper/bundle'
// import ScrollHelper from './scrollHelper.js'
// import ScrollMagic from 'scrollmagic'

import ScrollHelper from './includes/_ScrollHelper.js'
import ResizeHelper from './includes/_ResizeHelper.js'
import Device from './includes/_Device.js'

const SLIDE_SPEED = 40000

window.addEventListener('DOMContentLoaded', () => {
  const $body = document.querySelector('body')
  const scroll = new ScrollHelper(e => {
    const offset = window.innerHeight * 0.25
    const top = window.pageYOffset || document.documentElement.scrollTop
    if (top >= offset) {
      $body.classList.add('scrolled')
    } else {
      $body.classList.remove('scrolled')
    }
  })

  const swiper = new Swiper('.js-swiper', {
    slidesPerView: 'auto',
    spaceBetween: 0,
    noSwiping: true,
    threshold: 10,
    speed: SLIDE_SPEED,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    loop: true,
  })


  Vue.filter('colorID', function(index) {
    if (index === null) return ''
    return colorJson.colors[index].id
  })


  Vue.filter('colorLabel', function(value) {
    if (!value) return ''
    const found = colorJson.colors.filter((item, index) => {
      return item.id === value
    })
    return found.length ? found[0].name.en : value
  })

  const types = {
    "types": {
      "01": {
        "id": "MOUNTAINEER",
        "name": `マウンテニア`
      },
      "02": {
        "id": "ANCHOR",
        "name": `アンカー`
      },
    }
  }
  const simulation = new Vue({
    el: "#simulation",
    data: types,
    components: {
      'color': Color
    }
  })
  

})
